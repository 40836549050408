<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const vipBenefits = [
  {
    icon: t('vipCardGeneralInfo.features.featureOne.icon'),
    type: t('vipCardGeneralInfo.features.featureOne.type'),
    title: t('vipCardGeneralInfo.features.featureOne.title'),
    description: t('vipCardGeneralInfo.features.featureOne.description'),
  },
  {
    icon: t('vipCardGeneralInfo.features.featureTwo.icon'),
    type: t('vipCardGeneralInfo.features.featureTwo.type'),
    title: t('vipCardGeneralInfo.features.featureTwo.title'),
    description: t('vipCardGeneralInfo.features.featureTwo.description'),
  },
  {
    icon: t('vipCardGeneralInfo.features.featureThree.icon'),
    type: t('vipCardGeneralInfo.features.featureThree.type'),
    title: t('vipCardGeneralInfo.features.featureThree.title'),
    description: t('vipCardGeneralInfo.features.featureThree.description'),
  },
  {
    icon: t('vipCardGeneralInfo.features.featureFour.icon'),
    type: t('vipCardGeneralInfo.features.featureFour.type'),
    title: t('vipCardGeneralInfo.features.featureFour.title'),
    description: t('vipCardGeneralInfo.features.featureFour.description'),
  },
  {
    icon: t('vipCardGeneralInfo.features.featureFive.icon'),
    type: t('vipCardGeneralInfo.features.featureFive.type'),
    title: t('vipCardGeneralInfo.features.featureFive.title'),
    description: t('vipCardGeneralInfo.features.featureFive.description'),
  },
  {
    icon: t('vipCardGeneralInfo.features.featureSix.icon'),
    type: t('vipCardGeneralInfo.features.featureSix.type'),
    title: t('vipCardGeneralInfo.features.featureSix.title'),
    description: t('vipCardGeneralInfo.features.featureSix.description'),
  },
];

defineProps({
  designClasses: {
    type: String,
    default: "mt-[105px]",
  },
});
</script>

<template>
  <div>
    <div class="bg-[#D4CCB2] relative xl:mb-10" :class="designClasses">
      <nuxt-img src="images/background.png" alt="background" format="webp"
        class="bg-transparent xl:block hidden bg-cover pt-10 pb-8" width="602" height="819" />
      <div
        class="xl:absolute mt-12 md:mt-0 text-center text-white w-full pt-10 px-10 pb-16 top-10 font-extrabold text-lg leading-6 lg:text-3xl lg:leading-9">
        {{ t('vipCardGeneralInfo.title') }}
      </div>
    </div>
    <div
      class="xl:container xl:-mt-[806px] relative px-5 -mt-1.5 grid justify-items-center xl:place-items-stretch lg:grid-cols-2 xl:grid-cols-3 bg-[#D4CCB2] xl:bg-transparent lg:gap-x-[27px] lg:gap-y-6 lg:py-12">
      <template v-for="(item, i) in vipBenefits" :key="item">
        <HomepageVipBenefitCard :title="item.title" :description="item.description" :imageSrc="item.icon"
          :type="item.type" :class="[
      i !== 0 ? 'my-[34px] lg:my-6' : 'mb-[34px] lg:my-6',
    ]" />
      </template>
    </div>
  </div>

</template>
